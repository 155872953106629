<template>
  <div class="add">
    <div class="form">
      <el-form :model="formData"
               ref="formData"
               :rules="rules"
               hide-required-asterisk
               status-icon
               inline
               v-loading="loading"
               element-loading-text="正在提交"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(F, F, F, 0.01)">
        <el-form-item label="姓 名" prop="name">
          <el-input v-model="formData.name"
                    placeholder="请输入姓名"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="号 码"
                      prop="phone">
          <el-input v-model="formData.phone"
                    placeholder="请输入手机号码"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="类 型" prop="type">
          <el-select v-model="formData.type"
                     filterable
                     placeholder="请选择卡类型" @change="handleSelectChange">
            <el-option-group v-for="group in selectGroups"
                             :key="group.label"
                             :label="group.label">
              <el-option v-for="item in group.options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="总 计" prop="balance">
          <el-input v-model="formData.balance"
                    placeholder="卡内总计"
                    clearable
                    readonly></el-input>
        </el-form-item>
        <el-form-item label="备 注" prop="remark">
          <el-input v-model="formData.remark"
                    placeholder="办卡备注"
                    clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">办 卡</el-button>
          <el-button @click="resetForm('formData')">重 置</el-button>
        </el-form-item>
      </el-form>
      <el-button type="warning"
                 @click="searchAll"
                 round>会员卡统计
      </el-button>
    </div>
    <div class="dialog">
      <el-dialog title="会员卡统计" width="30%" :visible.sync="allCardInfo">
        <el-table :data="gridData">
          <el-table-column property="type" label="会员卡类型" width="250"></el-table-column>
          <el-table-column property="total" align="center" label="数 量" width="100"></el-table-column>
        </el-table>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import {get, post} from "@/utils/request";

  export default {
    name: 'Add',
    data() {
      let phoneValidator = (rule, value, callback) => {
        const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/;
        if (!reg.test(value)) {
          return callback(new Error("请输入正确的电话号码"));
        }
        this.$nextTick(() => {
          this.phoneVerify(value).then(res => {
            if (!res) {
              this.check = true;
              callback();
            } else {
              callback(new Error("此号码已注册会员"));
            }
          });
        })
      }
      return {
        // 会员卡信息列表
        gridData: [],
        // 控制加载图标显示
        loading: false,
        // 标记填入的手机号码是否已经注册
        check: false,
        // 控制会员卡信息显示
        allCardInfo: false,
        // 表单中类型选项框的选项
        selectGroups: [{
          label: '理发次数卡', options: [
            {label: '100元5次剪发卡', value: '100元5次剪发卡'},
            {label: '200元11次剪发卡', value: '200元11次剪发卡'},
          ]
        }, {
          label: '染烫折扣卡', options: [
            {label: '300元染烫8折卡', value: '300元染烫8折卡'},
            {label: '500元染烫7折卡', value: '500元染烫7折卡'},
            {label: '1000元染烫5折卡', value: '1000元染烫5折卡'},
          ]
        }
        ],
        // 表单信息
        formData: {
          name: '',
          phone: '',
          type: '',
          balance: '',
          remark: '',
          lastConsume: ''
        },
        // 表单验证规则
        rules: {
          name: [{required: true, message: '请输入姓名', trigger: 'blur'}],
          phone: [{validator: phoneValidator, trigger: 'blur'}]
        },

      }
    },
    methods: {
      // 查看当前号码是否已注册会员
      async phoneVerify(value) {
        let result;
        await post('/api/searchByPhone', value).then(res => {
          result = res;
        });
        return result.code == '0';
      },
      // 【类型】选择框选项变化时触发
      handleSelectChange(value) {
        switch (value) {
          case '100元5次剪发卡':
            this.formData.balance = '5';
            this.formData.remark = '100元5次剪发卡';
            break;
          case '200元11次剪发卡':
            this.formData.balance = '11';
            this.formData.remark = '200元11次剪发卡';
            break;
          case '300元染烫8折卡':
            this.formData.balance = '300';
            this.formData.remark = '300元染烫8折卡';
            break;
          case '500元染烫7折卡':
            this.formData.balance = '500';
            this.formData.remark = '500元染烫7折卡';
            break;
          case '1000元染烫5折卡':
            this.formData.balance = '1000';
            this.formData.remark = '1000元染烫5折卡';
            break;
          default:
            this.formData.balance = '';
            this.formData.remark = '';
            break;
        }
      },
      // 点击【办卡】按钮
      async submit() {

        if (!this.formData.name
            || !this.formData.phone
            || !this.formData.type) {
          this.$message.error('请将办卡信息完整输入');
        } else {
          if (!/^1[3|4|5|6|7|8][0-9]\d{8}$/.test(this.formData.phone)) {
            this.$message.error('请输入正确的电话号码');
          } else {
            if (this.check) {
              this.loading = true;
              let result;
              this.formData.lastConsume = new Date();
              await post("/api/addCard", this.formData).then(res => {
                result = res;
              });
              this.loading = false;
              if (result.code == '0') {
                this.$message({
                  type: 'success',
                  message: '办卡成功'
                });
              } else {
                this.$message.error(result.msg);
              }
            } else {
              this.$message.error('此号码已注册会员');
            }
          }
        }
      },
      // 点击【重置】按钮
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      // 点击【会员卡统计】按钮
      async searchAll() {
        this.loading = true;
        let result;
        this.$nextTick(() => {
          get("/api/total").then(res => {
            this.gridData = res.data;
            this.loading = false;
            this.allCardInfo = true;
          })
        });
      }
    }
  }
</script>

<style lang="less">
  .add {

    .form {
      width: 30%;
      margin: auto;
    }

  }
</style>